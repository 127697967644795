body, body.login-page {
    /*#28a745*/
 background-color: rgba(40, 167, 69, 0.7); 
 
}

body.report {
    
    background-color: #ffffff;
}

  
  .content-wrapper{
   
      background: transparent;
      
  }
  
  .login-box, .register-box {
    width: 500px;
}